export default [
  {
    path: "/",
    name: "main",
    meta: { title: "Main",  authRequired: true  },
    component: () => import("../layouts/vertical"),
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        meta: { title: 'Dashboard' },
        component: () => import("../views/dashboards/default")
      },
      {
        path: 'jobs',
        name: 'jobs',
        meta: { title: 'List jobs' },
        component: () => import("../views/jobs/list-jobs")
      },
      {
        path: 'jobs/:id/details',
        name: 'job-details',
        meta: { title: 'Detail job' },
        component: () => import("../views/jobs/detail-job")
      },
      {
        path: 'ocr',
        name: 'ocr',
        meta: { title: 'Upload job' },
        component: () => import("../views/jobs/upload-job")
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login"),
    meta: {
      title: "Login",
    }
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register"),
    meta: {
      title: "Register",
    }
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password"),
    meta: {
      title: "Forgot password",
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/account/logout"),
    meta: {
      title: "Logout",
      authRequired: true,
    }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    meta: { title: "404 Error Page" },
    component: require("../views/utility/404").default
  },
];
