import { defineStore } from "pinia";
import { jwtDecode } from "jwt-decode";
import { getFirebaseBackend } from '../../authUtils'
import router from '../../router/index'
import { userService } from '../../helpers/authservice/user.service';
import { useNotificationStore } from "./notification"

const user = JSON.parse(localStorage.getItem('user'));

export const useAuthStore = defineStore("auth", {
    state: () => ({
        status: user ? { loggeduser: true } : {},
        currentUser: localStorage.getItem('user'),
        user: user || null,
        token: localStorage.getItem('token')
    }),
    getters: {
        loggedIn() {
            return !!this.currentUser && !!this.token
        },
        tokenExpiration() {
            if (this.token) {
                const decodedToken = jwtDecode(this.token);
                return decodedToken.exp * 1000;
            }
            return null;
        }
    },
    actions: {
        login(email, password) {
            userService.login(email, password)
                .then(
                    (response) => {
                        this.loginSuccess(response.data.user)
                        this.setUser(response.data.user);
                        this.setToken(response.data.token);
                        router.push('/dashboard');
                    },
                    (error) => {
                        this.loginFailure()
                        const notificationStore = useNotificationStore();
                        notificationStore.error(error);
                    }
                );
        },
        setUser(user) {
            this.currentUser = user
            this.saveState('user', user)
        },
        setToken(token) {
            this.token = token;
            window.localStorage.setItem("token", JSON.stringify(token));
        },
        saveState(key, state) {
            window.localStorage.setItem(key, JSON.stringify(state))
        },
        resetPassword({ email } = {}) {
            return getFirebaseBackend().forgetPassword(email).then((response) => {
                const message = response.data
                this.validate()
                return message
            });
        },
        loginSuccess(user) {
            this.status = { loggeduser: true };
            this.user = user;
        },
        loginFailure() {
            this.status = {};
            this.user = null;
        },
        logOut() {
            this.setUser(null)
            return new Promise((resolve, reject) => {
                getFirebaseBackend().logout().then(() => {
                    resolve(true);
                }).catch((error) => {
                    reject(this._handleError(error));
                })
            });
        },
    },
})