import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3";
import { registerScrollSpy } from 'vue3-scroll-spy';
import { vMaska } from "maska";
import i18n from "./i18n";
import BootstrapVueNext from 'bootstrap-vue-next';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import "@/assets/scss/app.scss";
import "@vueform/multiselect/themes/default.css";
import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';

// Import DataTables library
import 'datatables.net';
import 'datatables.net-bs5'; // Import DataTables Bootstrap 5 styles if you're using Bootstrap 5

// PINIA
import pinia from '@/state/pinia'

// Create Vue app
const app = createApp(App);

// Use plugins and components
app
  .use(pinia)
  .use(router)
  .use(require('vue-chartist'))
  .use(BootstrapVueNext)
  .use(VueApexCharts)
  .use(vClickOutside)
  .use(i18n)
  .use(registerScrollSpy)
  .directive("maska", vMaska);

// Mount the app
app.mount('#app');

