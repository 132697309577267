import { createWebHistory, createRouter } from "vue-router";
import { beforeEachGuard, beforeResolveGuard } from "./authGuard";
import routes from "./routes";

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  }
});

router.beforeEach(beforeEachGuard);
router.beforeResolve(beforeResolveGuard);

export default router;