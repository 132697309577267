import { authHeader } from './auth-header';
import axios from 'axios';

const URL_API = process.env.VUE_APP_API_URL;
/* const token = localStorage.getItem('jwtToken');
const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
}; */

export const userService = {
    login,
    logout,
    register,
    getAll,
};

function login(email, password) {
    return axios.post(URL_API + "/auth/login", {
        email: email,
        password: password,
    })
}

function logout() {
    localStorage.removeItem('user');
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(`/users/register`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
