import { useAuthStore } from "@/state/pinia";
import appConfig from "../app.config.json";

export async function beforeEachGuard(routeTo, routeFrom, next) {
    const auth = useAuthStore();
    const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

    console.log(authRequired);
    if (!authRequired) return next();

    if (auth.loggedIn) {
        const tokenExpiration = auth.tokenExpiration;
        if (tokenExpiration && new Date(tokenExpiration) < new Date()) {
            return redirectToLogin();
        }

        return next();
    }

    redirectToLogin();

    function redirectToLogin() {
        next({ name: "login", query: { redirectFrom: routeTo.fullPath } });
    }
}

export async function beforeResolveGuard(routeTo, routeFrom, next) {
    try {
        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        if (args.length) {
                            next(...args);
                            reject(new Error("Redirected"));
                        } else {
                            resolve();
                        }
                    });
                } else {
                    resolve();
                }
            });
        }
    } catch (error) {
        return;
    }

    document.title = routeTo.meta.title + " | " + appConfig.title;
    next();
}